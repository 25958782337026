import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { TimeService } from 'src/app/shared/time/services/time.service';

@Component({
    selector: 'app-event-search',
    templateUrl: './event-search.component.html',
    styleUrls: ['./event-search.component.scss'],
})
export class EventSearchComponent implements OnInit {
    @Output() public searchExecuted = new EventEmitter<void>();

    public form = new FormGroup({
        searchTerm: new FormControl(''),
        filters: new FormGroup({
            location: new FormControl(''),
            startTime: new FormControl(''),
            endTime: new FormControl(''),
            tags: new FormControl<Map<string, boolean>>(new Map()),
        }),
    });

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private timeService: TimeService
    ) { }

    public ngOnInit(): void {
        this.route.queryParams
            .pipe(
                filter((params) => Object.keys(params).length > 0),
                tap((params) => {
                    if (params['query']) {
                        this.form.controls['searchTerm'].patchValue(params['query']);
                    }
                    if (params['location']) {
                        this.form.controls['filters'].controls['location'].patchValue(
                            params['location']
                        );
                    }
                    if (params['startTime'] && params['endTime']) {
                        const startDate =
                            this.timeService.convertEpochMillisecondsToDateString(
                                +params['startTime']
                            );
                        this.form.controls['filters'].controls['startTime'].patchValue(
                            startDate
                        );

                        const endDate =
                            this.timeService.convertEpochMillisecondsToDateString(
                                +params['endTime']
                            );
                        this.form.controls['filters'].controls['endTime'].patchValue(
                            endDate
                        );
                    }
                    if (params['tags']) {
                        const map = this.form.controls['filters'].controls['tags'].value!;

                        const tags = (params['tags'] as string).split(',');
                        tags.forEach((tag) => {
                            map.set(tag, true);
                        });
                    }
                })
            )
            .subscribe();
    }

    public searchEvents(): void {
        const params: any = {};
        const filters = this.form.controls['filters'].value;

        if (this.form.get('searchTerm')!.value) {
            params['query'] = this.form.get('searchTerm')!.value ?? '';
        }

        if (filters.location) {
            params['location'] = filters.location;
        }

        if (filters.startTime && filters.endTime) {
            params['startTime'] = this.timeService
                .convertDateStringToEpochMilliseconds(filters.startTime)
                .toString();
            params['endTime'] = this.timeService
                .convertDateStringToEpochMilliseconds(filters.endTime)
                .toString();
        }

        if (filters.tags?.size && filters.tags.size > 0) {
            params['tags'] = [...filters.tags.keys()].join(',');
        }

        this.searchExecuted.emit();

        this.router.navigate([`/events/search`], { queryParams: params });
    }
}
