<div class="dropdown">
  <button
    class="btn btn-secondary dropdown-toggle"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {{ label }}
  </button>
  <ul class="dropdown-menu">
    <li *ngFor="let option of options" (click)="optionSelected.emit(option)">
      <a class="dropdown-item">{{ option }}</a>
    </li>
  </ul>
</div>
