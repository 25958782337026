<form *ngIf="form" [formGroup]="form">
  <div>Location</div>
  <div>
    <select
      class="form-select"
      formControlName="location"
      (change)="selectLocation($event)"
    >
      <option value="">Any</option>
      <option
        *ngFor="let location of locations"
        [value]="location.abbreviation"
      >
        {{ location.name }}
      </option>
    </select>
  </div>

  <div class="d-flex flex-column flex-md-row my-4 my-md-2">
    <app-date-time-field
      [control]="startTimeControl"
      label="Start Time"
    ></app-date-time-field>
    <app-date-time-field
      class="ms-md-4 mt-2 mt-md-0"
      [control]="endTimeControl"
      label="End Time"
    ></app-date-time-field>
  </div>

  <ng-container *ngIf="selectedTags">
    <span
      [ngClass]="{
        'bg-secondary': !selectedTags.has(tag),
        'bg-primary': selectedTags.has(tag)
      }"
      class="mt-3 mx-2 badge rounded-pill fs-6"
      role="button"
      *ngFor="let tag of tags"
      (click)="selectTag(tag)"
    >
      {{ tag }}
    </span>
  </ng-container>

  <div class="d-flex mt-2 justify-content-center">
    <button
      type="button"
      class="btn btn-success filter-button apply-filters-button"
      (click)="searchMethod()"
    >
      Apply Filters
    </button>
    <button
      type="button"
      class="btn btn-primary filter-button"
      (click)="clearFiltersAndReload()"
    >
      Clear Filters
    </button>
  </div>
</form>
