import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';

@NgModule({
    declarations: [
        ProgressBarComponent,
        SpinnerOverlayComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ProgressBarComponent,
        SpinnerOverlayComponent
    ]
})
export class SharedProgressModule { }
