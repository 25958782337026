import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EVENT_TIME_FORMAT } from '../../config/events-shared-settings';
import { Event } from '../../models/event';

@Component({
    selector: 'app-events-table',
    templateUrl: './events-table.component.html',
    styleUrls: ['./events-table.component.scss']
})
export class EventsTableComponent {
    public readonly EVENT_TIME_FORMAT = EVENT_TIME_FORMAT;

    @Output() public eventSelected = new EventEmitter<string>();
    @Output() public approve = new EventEmitter<Event>();
    @Output() public edit = new EventEmitter<string>();
    @Output() public deny = new EventEmitter<Event>();
    @Output() public delete = new EventEmitter<Event>();

    @Input() public events: Event[] = [];
    @Input() public selectedEventId: string | undefined;
    @Input() public approvingEvent: boolean = false;
    @Input() public denyingEvent: boolean = false;
    @Input() public deletingEvent: boolean = false;
    @Input() public allowApprove: boolean = false;
    @Input() public allowDeny: boolean = false;
    @Input() public allowDelete: boolean = false;
}
