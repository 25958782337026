import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { User } from 'src/app/user/models/user';
import { UserService } from 'src/app/user/services/user.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public showSearch: boolean = false;
    public currentUser: User | undefined

    constructor(
        private userService: UserService,
        private authService: AuthService
    ) { }

    public ngOnInit(): void {
        this.userService.currentUser.subscribe((user) => {
            this.currentUser = user;
        });
    }

    public closeDrawer(): void {
        const closeDrawerIcon = document.querySelector('#closeNormalDrawerButton') as HTMLElement;
        if (closeDrawerIcon) {
            closeDrawerIcon.click();
        }
    }

    public closeSearchDrawer(): void {
        const closeSearchDrawerIcon = document.querySelector('#closeSearchDrawerButton') as HTMLElement;
        if (closeSearchDrawerIcon) {
            closeSearchDrawerIcon.click();
        }
    }

    public logout(): void {
        this.authService.logout();
    }
}
