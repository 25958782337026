import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SharedIconsModule } from '../shared/icons/shared-icons.module';
import { RouterModule } from '@angular/router';
import { EventsSearchModule } from '../events/search/events-search.module';


@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports: [
        CommonModule,
        EventsSearchModule,
        RouterModule,
        SharedIconsModule
    ],
    exports: [
        NavbarComponent,
    ]
})
export class NavbarModule { }
