import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, take, takeUntil, timer } from 'rxjs';
import { AlertsService } from 'src/app/alerts/services/alerts.service';
import { AlertType } from 'src/app/alerts/types/alert.type';
import { FormValidationService } from 'src/app/shared/forms/services/form-validation.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-confirmation-page',
    templateUrl: './confirmation-page.component.html',
    styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent implements OnInit, OnDestroy {
    private username!: string;
    public canSendCodeAgain: boolean = false;
    public showCodeEntry: boolean = false;
    public codeRetryCountdown: number = 0;

    private resetTimer = new Subject<void>();

    constructor(
        @Inject('Window') private window: Window,
        private router: Router,
        private authService: AuthService,
        private alertsService: AlertsService,
        private formValidationService: FormValidationService
    ) { }

    public form = new FormGroup({
        code: new FormControl('', this.formValidationService.requiredField('Code')),
    });

    public ngOnInit(): void {
        this.username = this.window.history.state.username;

        if (!this.username) {
            this.alertsService.createAlert({
                alertType: AlertType.Warning,
                alertMessage: 'Please log in to the application.'
            });

            this.router.navigate(['/login']);
        }

        this.activateResendCodeTimer();
    }

    public resendCode() {
        this.activateResendCodeTimer();

        if (this.username) {
            this.authService.resendConfirmationCode(this.username);
        }
    }

    public trimWhitespace(text: string): void {
        if (!text) {
            return;
        }

        const value = text.trim();

        if (this.form.get('code')!.value !== value) {
            this.form.controls['code'].patchValue(value);
        }
    }

    public submitCode() {
        if (this.form.valid) {
            this.authService.confirmUser(this.form.value.code!, this.username!).subscribe((userConfirmed) => {
                if (userConfirmed) {
                    this.router.navigate(['/login'], { state: { confirmed: true } });
                }

                this.form.reset();
            });
        }
    }

    private activateResendCodeTimer() {
        this.resetResendCodeTimer();
        this.codeRetryCountdown = 30;

        timer(1000, 1000).pipe(
            takeUntil(this.resetTimer),
            take(31)
        ).subscribe(() => {
            if (this.codeRetryCountdown === 0) {
                this.canSendCodeAgain = true;
            }
            else this.codeRetryCountdown--;

        });

        this.canSendCodeAgain = false;
    }

    private resetResendCodeTimer(): void {
        this.resetTimer.next();
    }

    public ngOnDestroy(): void {
        this.resetTimer.complete();
    }
}
