import { Injectable } from '@angular/core';
import { PhoneFormatter } from 'src/app/shared/formatters/phone.formatter';
import { Event } from '../models/event';

@Injectable({
    providedIn: 'root'
})
export class EventMapper {

    constructor() { }

    public mapEventFromData(event: any, eventImagesMap: any): Event {
        const imageUrls: string[] = [];

        const images = eventImagesMap ? eventImagesMap[event.id] : null;

        if (typeof images === 'object' && images !== null) {
            imageUrls.push(...images);
        } else if (images !== null && images !== undefined) {
            imageUrls.push(images);
        }

        if (imageUrls?.length === 0) {
            imageUrls.push('assets/images/concert-default.jpg');
        }

        const mappedEvent: Event = {
            id: event.id,
            title: event.title,
            description: event.description,
            address: {
                addressOne: event.addressOne,
                addressTwo: event.addressTwo,
                city: event.city,
                state: event.state,
                zip: event.zip,
            },
            category: event.category,
            endDateTime: event.endDateTime,
            eventContactDetails: {
                email: event.eventEmail,
                phone: PhoneFormatter.formatPhoneNumber(event.eventPhone),
                website: event.eventWebsite,
            },
            organizerContactDetails: {
                name: event.organizer,
                email: event.organizerEmail,
                phone: PhoneFormatter.formatPhoneNumber(event.organizerPhone),
            },
            location:
                event.locationLatitude !== undefined && event.locationLongitude
                    ? {
                        lat: +event.locationLatitude,
                        lng: +event.locationLongitude,
                    }
                    : undefined,
            startDateTime: event.startDateTime,
            thumbnailUrls: imageUrls,
        };

        return mappedEvent;
    }
}
