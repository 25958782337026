<app-modal #modal>
    <ng-container header>
        <h5>Select Thumbnail</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></button>
    </ng-container>
    <ng-container body>
        <app-spinner-overlay class="d-block m-4 ms-auto me-auto" id="pageSpinner" [showProgress]="retrievingThumbnails"
            *ngIf="retrievingThumbnails">
        </app-spinner-overlay>

        <ng-container *ngIf="!retrievingThumbnails && thumbnails !== undefined">
            <div class="row mb-4">
                <app-dropdown [label]="'Category: ' + selectedThumbnailCategory"
                    [selectedOption]="selectedThumbnailCategory" [options]="thumbnailCategories"
                    (optionSelected)="selectThumbnailCategory($event)"></app-dropdown>
            </div>

            <div class="row">
                <div *ngFor="let thumbnail of thumbnailsForPage; let i = index" class="col-4"
                    style="border: solid; cursor: pointer" [ngStyle]="{
            'border-color':
              selectedIndex === i + (currentPage - 1) * PAGE_SIZE
                ? 'red'
                : 'transparent'
          }" (click)="selectThumbnail(i + +((currentPage - 1) * PAGE_SIZE))">
                    <img style="width: 100%" [src]="thumbnail" crossorigin="anonymous" />
                </div>
            </div>
            <div class="d-flex mt-1" style="justify-content: center">
                <button class="btn btn-primary me-1" [disabled]="currentPage === 1"
                    (click)="changePage(currentPage - 1)">
                    <app-icon icon="chevron-left"></app-icon>
                </button>
                <button class="btn btn-primary ms-1" [disabled]="currentPage === numPages"
                    (click)="changePage(currentPage + 1)">
                    <app-icon icon="chevron-right"></app-icon>
                </button>
            </div>
            <div class="row mt-4">
                <img style="width: 100%" [src]="thumbnailsForCategory[selectedIndex]" crossorigin="anonymous" />
            </div>
        </ng-container>
    </ng-container>
    <ng-container footer>
        <button class="btn btn-primary w-100" (click)="applyThumbnail()">
            Apply
        </button>
    </ng-container>
</app-modal>