<app-text-field class="my-2" type="password" [control]="control" label="Password">
</app-text-field>
<div class="text-muted text-start fst-italic" *ngIf="(!control.errors && control.touched) || control.untouched">
    <div>Password requirements:</div>
    <ul>
        <li>At least 8 characters long</li>
        <li>At least one uppercase letter</li>
        <li>At least one lowercase letter</li>
        <li>
            <div class="d-flex">
                <div>At least one</div>
                <a class="ms-1" data-bs-toggle="tooltip" data-bs-placement="top"
                    [attr.title]="allowedSpecialCharactersString">special character</a>
            </div>
        </li>
        <li>At least one number</li>
    </ul>
</div>
