import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent {
    // HTML helpers
    public readonly objectFn = Object;
    @Output() public modelChange = new EventEmitter<string>();

    @Input() public control: FormControl | undefined;
    @Input() public label: string | undefined = '';
    @Input() public type: 'text' | 'email' | 'password' = 'text';
}
