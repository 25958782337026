import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CookiesService {
    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    public getCookie(cookieName: string): string {
        const cookies = this.getCookies();
        return cookies[cookieName];
    }

    public setCookie(cookieName: string, value: string): void {
        const cookies = this.getCookies();
        cookies[cookieName] = value;

        const output = JSON.stringify(cookies);
        const encodedOutput = window.btoa(output);

        this.document.cookie = encodedOutput;
    }

    private getCookies(): any {
        const cookies = window.atob(this.document.cookie);

        return cookies ? JSON.parse(cookies) : {};
    }
}