import { AfterViewInit, ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { AnalyticsEvents } from './analytics/enums/analytics-events';
import { AnalyticsService } from './analytics/services/analytics.service';
import { ModalService } from './shared/modal/services/modal.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    @ViewChild('modalContainer', { read: ViewContainerRef }) private modalContainer: ViewContainerRef | undefined;

    constructor(
        private modalService: ModalService,
        private changeDetector: ChangeDetectorRef,
        private analyticsService: AnalyticsService
    ) { }

    public ngAfterViewInit(): void {
        if (this.modalContainer) {
            this.modalService.registerModalContainer(this.modalContainer);

            this.changeDetector.detectChanges();
        }

        this.analyticsService.logEvent({
            eventName: AnalyticsEvents.PAGE_VISIT
        });
    }
}