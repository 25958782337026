<div *ngIf="control">
  <div class="d-flex">
    <div>{{ label }}</div>
    <div *ngIf="required" class="ms-1" [style.color]="'red'">*</div>
  </div>
  <input type="datetime-local" [formControl]="control" />
  <ng-container *ngIf="control!.errors && control!.touched">
    <div
      class="error ps-2 text-start"
      *ngFor="let key of objectFn.keys(control!.errors!)"
    >
      {{ control!.errors![key] }}
    </div>
  </ng-container>
</div>
