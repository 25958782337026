import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { State } from 'src/app/locations/models/state';
import { StatesService } from 'src/app/locations/services/states.service';
import { TagsService } from 'src/app/shared/tags/services/tags.service';

@Component({
    selector: 'app-event-filters',
    templateUrl: './event-filters.component.html',
    styleUrls: ['./event-filters.component.scss'],
})
export class EventFiltersComponent implements OnInit {
    @Input() public form: FormGroup | undefined;
    @Input() public searchMethod!: Function;

    public locations: State[] = [];
    public tags: string[] = [];

    public get selectedTags(): Map<string, boolean> {
        return this.form?.controls['tags'].value;
    }

    public get startTimeControl(): FormControl | undefined {
        return this.form?.controls['startTime'] as FormControl;
    }

    public get endTimeControl(): FormControl | undefined {
        return this.form?.controls['endTime'] as FormControl;
    }

    constructor(
        private tagsService: TagsService,
        private statesService: StatesService
    ) { }

    public ngOnInit(): void {
        this.tags = this.tagsService.getTags();

        this.locations = this.statesService.getStates();
    }

    private _clearFilters(): void {
        this.form?.reset({
            location: '',
            tags: new Map<string, boolean>(),
        });
    }

    public selectTag(tag: string): void {
        const selectedTags = this.form!.controls['tags'].value;

        const isSelected = selectedTags.has(tag);

        !isSelected ? selectedTags.set(tag, true) : selectedTags.delete(tag);
    }

    public selectLocation(event: any): void {
        const location = event.target.value as string;

        this.form!.controls['location'].patchValue(location);
    }

    public clearFiltersAndReload() {
        this._clearFilters();
        this.searchMethod();
    }
}
