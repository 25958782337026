import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FormValidationService } from 'src/app/shared/forms/services/form-validation.service';
import { AuthService } from '../../services/auth.service';
import { FormControlGenerationService } from 'src/app/shared/forms/services/form-control-generation.service';

@Component({
    selector: 'app-reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
    private username: string | undefined;

    constructor(
        @Inject('Window') private window: Window,
        private authService: AuthService,
        private router: Router,
        private formValidationService: FormValidationService,
        private formControlGenerationService: FormControlGenerationService
    ) { }

    public form = new FormGroup({
        password: this.formControlGenerationService.createPasswordForm(),
        code: new FormControl('', this.formValidationService.requiredField('Confirmation code'))
    });

    public ngOnInit(): void {
        this.username = this.window.history.state.username;

        if (!this.username) {
            this.router.navigate(['/login']);
        }
    }

    public resetPassword(): void {
        if (this.form.invalid) {
            return;
        }

        const username = this.username;
        const password = this.form.get('password')?.value;
        const code = this.form.get('code')?.value;

        this.authService.confirmResetPassword(username!, password!, code!).pipe(
            switchMap((isConfirmed) => {
                if (isConfirmed) {
                    return this.authService.login({
                        username: username!,
                        password: password!
                    }).pipe(
                        map((response) => response.successful)
                    );
                }

                else {
                    return observableOf(false);
                }
            })
        ).subscribe((isSuccessful) => {
            if (isSuccessful) {
                this.router.navigate(['/home']);
            }
        });
    }
}
