<div class="d-flex flex-column align-items-center w-100 mx-auto text-center">
    <div class="header">Confirm</div>

    <div class="mt-2">Please check your inbox for a verification code.</div>

    <form [formGroup]="form" (ngSubmit)="submitCode()">
        <app-text-field class="mt-4 p-2" type="text" [control]="form.controls['code']" label="Enter code here"
            (modelChange)="trimWhitespace($event)">
        </app-text-field>

        <div class="mt-3 d-grid">
            <button class="btn btn-primary" type="submit" role="button">Submit</button>
            <button class="btn btn-secondary d-flex justify-content-center mt-4" [disabled]="!canSendCodeAgain"
                role="button" (click)="resendCode()">
                <div>
                    Resend Code
                </div>
                <div *ngIf="!canSendCodeAgain" class="ms-1">
                    {{ '(' + codeRetryCountdown.toString() + ')' }}
                </div>
            </button>
        </div>
    </form>
</div>