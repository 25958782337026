import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import * as bootstrap from 'bootstrap';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit, OnDestroy {
    private _lockBackground: boolean = false;

    public get lockBackground(): boolean {
        return this._lockBackground;
    }

    @Input() public set lockBackground(lockBackground: boolean) {
        this._lockBackground = lockBackground;

        this.createModal();
    }

    private modal: bootstrap.Modal | undefined;

    public ngAfterViewInit(): void {
        this.createModal();
    }

    public createModal(): void {
        const modal = document.querySelector('#modal');

        if (modal) {
            this.dispose();

            this.modal = new bootstrap.Modal(modal, {
                backdrop: this._lockBackground ? 'static' : true,
                keyboard: !this._lockBackground
            });

            this.show();
        }
    }

    public show(): void {
        this.modal?.show();
    }

    public hide(): void {
        this.modal?.hide();
    }

    private dispose(): void {
        this.hide();
        this.modal?.dispose();
        this.modal = undefined;

    }

    public ngOnDestroy(): void {
        this.dispose();
    }
}
