<div class="modal" tabindex="-1" aria-hidden="true" id="modal" data-bs-focus="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <ng-content select="[header]"></ng-content>
            </div>
            <div class="modal-body">
                <ng-content select="[body]"></ng-content>
            </div>
            <div class="modal-footer">
                <ng-content select="[footer]"></ng-content>
            </div>
        </div>
    </div>
</div>