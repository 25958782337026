import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, filter, take } from "rxjs";
import { ModalService } from "../../services/modal.service";
import * as uuid from 'uuid';
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: 'app-base-modal',
    template: '<ng-container></ng-container>'
})
export class BaseModalComponent implements OnInit, OnDestroy {
    public readonly modalId: string = uuid.v4();

    @ViewChild(ModalComponent) protected modalComponent: ModalComponent | undefined;

    private created = new BehaviorSubject<boolean>(false);

    constructor(
        protected modalService: ModalService
    ) { }

    public ngOnInit(): void {
        if (!this.modalId) {
            this.created.error(new Error('No modal ID configured for modal'));

            return;
        }

        this.created.next(true);
    }

    public showModal(): void {
        this.modalComponent?.show();
    }

    public sendResponse(response: any): void {
        this.created.pipe(
            filter((created) => created),
            take(1)
        ).subscribe(() => {
            this.modalService.sendResponse(response, this.modalId);
        });
    }

    public closeModal(response?: any): void {
        this.created.pipe(
            filter((created) => created),
            take(1)
        ).subscribe(() => {
            this.modalService.closeModal(response);
        });
    }

    public hideModal(): void {
        this.modalComponent?.hide();
    }

    public ngOnDestroy(): void {
        this.created.complete();
    }
}