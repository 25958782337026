import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as bootstrap from 'bootstrap';
import { UserSignupRequest } from 'src/app/auth/requests/user-signup.request';
import { AuthService } from 'src/app/auth/services/auth.service';
import { FormValidationService } from 'src/app/shared/forms/services/form-validation.service';
import { PasswordValidationService } from 'src/app/shared/forms/services/password-validation.service';

@Component({
    selector: 'app-organization-registration-form',
    templateUrl: './organization-registration-form.component.html',
    styleUrls: ['./organization-registration-form.component.scss']
})
export class OrganizationRegistrationFormComponent implements AfterViewInit {
    @Output() public formSubmitted = new EventEmitter<string>();

    public readonly allowedSpecialCharactersString: string = "^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \ , > < ' : ; | _ ~ ` + =";

    public form = new FormGroup({
        name: new FormControl('', [this.formValidationService.requiredField('Organization name')]),
        email: new FormControl('', [this.formValidationService.requiredField('Email')]),
        password: new FormControl('', [
            this.formValidationService.requiredField('Password'),
            this.formValidationService.minimumLength(8),
            this.passwordValidationService.requireUppercaseCharacter(),
            this.passwordValidationService.requireLowercaseCharacter(),
            this.passwordValidationService.requireSpecialCharacter(/[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`\+\=]/),
            this.passwordValidationService.requireNumber()
        ]),
        confirmPassword: new FormControl(''),
    }, {
        validators: [
            this.formValidationService.fieldsMatch({
                sourceField: 'password',
                confirmationField: 'confirmPassword',
                errorName: 'passwordMismatch',
                errorMessage: 'Passwords must match'
            })
        ]
    });

    constructor(
        private authService: AuthService,
        private formValidationService: FormValidationService,
        private passwordValidationService: PasswordValidationService
    ) { }

    public ngAfterViewInit(): void {
        const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

        tooltipElements.forEach((element) => {
            new bootstrap.Tooltip(element);
        });
    }

    public register(): void {
        if (this.form.valid) {
            const user = this.form.value;

            const request: UserSignupRequest = {
                name: user.name!,
                email: user.email!,
            };

            this.authService.signup(request, user.password!).subscribe((registrationSuccessful) => {
                if (registrationSuccessful) {
                    this.formSubmitted.emit(user.email!);
                }
            });
        }
    }
}
