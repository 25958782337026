<div *ngIf="event" class="card">
  <div class="card-img-top position-relative d-flex justify-content-center">
    <div class="image-background-container text-center">
      <img
        class="image-background"
        [src]="event!.thumbnailUrls![0]"
        onerror="this.src='assets/images/concert-default.jpg'; this.onerror=''"
      />
    </div>
    <img
      [src]="event!.thumbnailUrls![0]"
      class="h-100 w-100 position-absolute"
      onerror="this.src='assets/images/concert-default.jpg'; this.onerror=''"
    />
  </div>

  <div class="card-body pt-1 d-flex flex-column justify-content-between">
    <div class="mb-2 fs-3 text-center">
      {{ event.title }}
    </div>

    <div class="row">
      <div *ngIf="event.address" class="col-6 text-center">
        <app-icon icon="map-pin"></app-icon>
        {{ event.address.addressOne }}, {{ event.address.city }},
        {{ event.address.state }}
      </div>

      <div class="col-6 text-center">
        <div>
          <app-icon icon="clock"></app-icon>
          {{ event.startDateTime | date : EVENT_TIME_FORMAT }}
        </div>
        <div>to</div>
        <div>
          <app-icon icon="clock"></app-icon>
          {{ event.endDateTime | date : EVENT_TIME_FORMAT }}
        </div>
      </div>
    </div>
  </div>
</div>
