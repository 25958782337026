import { NgModule } from '@angular/core';
import { TimeService } from './services/time.service';


@NgModule({
    providers: [
        TimeService
    ],
})
export class SharedTimeModule { }
