import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { SearchEventsRequest } from 'src/app/events/shared/requests/search-events.request';
import { EventsService } from 'src/app/events/shared/services/events.service';
import { Event } from "src/app/events/shared/models/event";

@Component({
    selector: 'app-event-search-results-page',
    templateUrl: './event-search-results-page.component.html',
    styleUrls: ['./event-search-results-page.component.scss']
})
export class EventSearchResultsPageComponent implements OnInit {
    public events: Event[] = [];
    public searching: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private eventsService: EventsService
    ) { }

    public ngOnInit(): void {
        this.route.queryParams.pipe(
            switchMap((params) => {
                const request: SearchEventsRequest = {
                    searchTerm: params['query'],
                    startTime: params['startTime'],
                    endTime: params['endTime'],
                    state: params['location'],
                    tags: params['tags'] !== undefined ? (params['tags'] as string).split(',') : undefined
                };

                this.searching = true;

                return this.eventsService.searchEvents(request);
            })
        ).subscribe((response) => {
            this.events = response.events;
            this.searching = false;
        });
    }

}
