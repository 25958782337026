<app-spinner-overlay class="m-2" *ngIf="searching" [showProgress]="searching">
</app-spinner-overlay>

<div class="row">
  <app-event-card
    role="button"
    *ngFor="let event of events"
    [event]="event"
    class="col-md-4 mt-4"
    [routerLink]="['/events', event.id, 'details']"
  ></app-event-card>
  <h1 *ngIf="events.length === 0 && !searching" class="no-events-header">
    No events found using the applied filters.
  </h1>
</div>
