import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AnalyticsInterceptor } from './interceptors/analytics-interceptor';
import { AnalyticsService } from './services/analytics.service';



@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        AnalyticsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AnalyticsInterceptor,
            multi: true
        }
    ]
})
export class AnalyticsModule { }
