import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AssetsDataService } from './assets-data.service';

@Injectable()
export class AssetsService {
    constructor(private assetsDataService: AssetsDataService) { }

    public getThumbnails(): Observable<Map<string, string[]>> {
        return this.assetsDataService.getThumbnails().pipe(
            map((response) => {
                const categories = Object.keys(response);
                const categoryMap = new Map<string, string[]>();

                for (const category of categories) {
                    categoryMap.set(category, response[category]);
                }

                return categoryMap;
            })
        );
    }

    public getRandomThumbnail(): Observable<string | undefined> {
        return this.getThumbnails().pipe(
            map((thumbnails) => {
                const categories = Array.from(thumbnails.keys());
                if (thumbnails && categories.length > 0) {
                    const categories = Object.keys(thumbnails);

                    const numCategories = categories.length;

                    let randomCategoryIndex =  this.generateRandomNumber(numCategories) - 1;

                    const randomCategory = Array.from(thumbnails.keys())[randomCategoryIndex];

                    const categoryThumbnails = thumbnails.get(randomCategory);

                    const numCategoryThumbnails = categoryThumbnails?.length;

                    let randomCategoryThumbnailIndex = this.generateRandomNumber(numCategoryThumbnails!) - 1;

                    const thumbnail = categoryThumbnails![randomCategoryThumbnailIndex];

                    return thumbnail;
                }

                return undefined;
            })
        );
    }

    private generateRandomNumber(limit: number): number {
        return Math.floor(Math.random() * limit) + 1;
    }
}
