import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IconsService {
    constructor(
        private http: HttpClient
    ) { }
    public getIcons(): Observable<string[]> {
        return this.http.get<{ icons: string[] }>("./assets/icons/icons-list.json").pipe(
            map((listObject) => listObject.icons)
        );
    }
}
