import { Injectable } from '@angular/core';
import { State } from '../models/state';

@Injectable({
    providedIn: 'root'
})
export class StatesService {

    public getStates(): State[] {
        return [
            {
                name: 'Colorado',
                abbreviation: 'CO'
            },
            {
                name: 'Florida',
                abbreviation: 'FL'
            },
            {
                name: 'Michigan',
                abbreviation: 'MI'
            }
        ];
    }
}
