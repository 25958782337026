export class UserData {
    public readonly username!: string;
    public readonly name?: string;
    public readonly given?: string | undefined;
    public readonly family?: string | undefined;
    public readonly email!: string;
    public readonly userId!: string;

    constructor(data: UserData) {
        if (!data || !data.username) return;

        this.name = data.name;
        this.username = data.username;
        this.given = data.given;
        this.family = data.family;
        this.email = data.email;
        this.userId = data.userId;
    }
}
