export enum CATEGORIES {
    ART = 'Art',
    CARS = 'Cars',
    CHARITY = 'Charity',
    CONFERENCE = 'Conference',
    CRAFTS = 'Crafts',
    EXPO = 'Expo',
    FESTIVAL = 'Festival',
    FOOD = 'Food',
    KIDS = 'Kids',
    MUSIC = 'Music',
    NIGHTLIFE = 'Nightlife',
    SPORTS = 'Sports',
    SOCIAL = 'Social',
    OTHER = 'Other'
}