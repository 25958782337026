import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidationService } from 'src/app/shared/forms/services/form-validation.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent {

    constructor(
        private authService: AuthService,
        private router: Router,
        private formValidationService: FormValidationService
    ) { }

    public form = new FormGroup({
        email: new FormControl('', this.formValidationService.requiredField('Email')),
    });

    public resetPassword(): void {
        if (this.form.invalid) {
            return;
        }

        const username = this.form.get('email')?.value;

        this.authService.resetPassword(username!).subscribe((isSent) => {
            if (isSent) {
                this.router.navigate(['/reset-password'], { state: { username: username } });
            }
        });
    }
}
