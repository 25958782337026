<div class="d-flex flex-column align-items-center w-100 mx-auto text-center">
    <div class="header">Confirm Password</div>
    <form [formGroup]="form" (ngSubmit)="resetPassword()">
        <app-password-field [control]="form.controls['password']"></app-password-field>

        <app-text-field class="mt-4 p-2" type="text" [control]="form.controls['code']" label="Confirmation code">
        </app-text-field>
    
        <div class="mt-3 d-grid">
            <button class="btn btn-primary" type="submit" role="button" [disabled]="form.invalid">Submit</button>
        </div>
    </form>
</div>