import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of as observableOf, tap } from 'rxjs';
import { AlertsService } from 'src/app/alerts/services/alerts.service';
import { AlertType } from 'src/app/alerts/types/alert.type';
import { AnalyticsEvents } from 'src/app/analytics/enums/analytics-events';
import { AnalyticsService } from 'src/app/analytics/services/analytics.service';
import { API_URL_TOKEN } from 'src/app/shared/tokens';
import { Event } from '../models/event';
import { CreateEventRequest } from '../requests/create-event.request';
import { SearchEventsRequest } from '../requests/search-events.request';
import { CreateEventResponse } from '../responses/create-event.response';
import { SearchEventsResponse } from '../responses/search-events.response';
import { EventMapper } from './event-mapper.service';

@Injectable()
export class EventsService {
    constructor(
        private http: HttpClient,
        @Inject(API_URL_TOKEN) private apiUrl: string,
        private alertsService: AlertsService,
        private eventsMapper: EventMapper,
        private analyticsService: AnalyticsService
    ) { }

    public searchEvents(
        request: SearchEventsRequest
    ): Observable<SearchEventsResponse> {
        let params = new HttpParams().append('start', '0').append('limit', '10');

        if (request.searchTerm) {
            params = params.append('content', request.searchTerm);
        }

        if (request.state) {
            params = params.append('state', request.state);
        }

        if (request.startTime && request.startTime > 0) {
            params = params
                .append('minstarttime', request.startTime);
        }

        if (request.endTime && request.endTime > 0) {
            params = params
                .append('maxstarttime', request.endTime);
        }

        if (request.tags) {
            params = params.append('tags', request.tags.join(','));
        }

        return this.http
            .get<any>(`${this.apiUrl}/events/search`, { params: params })
            .pipe(
                map((response) => {
                    const events = response.events.map((event: any) =>
                        this.eventsMapper.mapEventFromData(event, response.eventImagesMap)
                    );

                    return {
                        events,
                    };
                }),
                catchError(() => {
                    this.alertsService.createAlert({
                        alertType: AlertType.Error,
                        alertMessage: 'An error occurred while searching for events. Please try again later.'
                    });

                    return observableOf({
                        events: []
                    });
                })
            );
    }

    public getEvent(eventId: string): Observable<Event | undefined> {
        return this.http.get<any>(`${this.apiUrl}/events/${eventId}`).pipe(
            map((response) => {
                const event = this.eventsMapper.mapEventFromData(response?.event, {
                    [`${response?.event.id}`]: response?.s3SignedUrl,
                });
                return event;
            }),
            catchError(() => {
                this.alertsService.createAlert({
                    alertType: AlertType.Error,
                    alertMessage: 'An error occurred while retrieving the event. Please try again.'
                });

                return observableOf(undefined);
            }),
            tap(() => {
                this.analyticsService.logEvent({
                    eventName: AnalyticsEvents.EVENT_VIEWED
                });
            }),
        );
    }

    public getPendingEvent(eventId: string): Observable<Event | undefined> {
        return this.http.get<any>(`${this.apiUrl}/events/pending/${eventId}`).pipe(
            map((response) => {
                const event = this.eventsMapper.mapEventFromData(response?.event, {
                    [`${response?.event.id}`]: response?.s3SignedUrl,
                });
                return event;
            }),
            catchError(() => {
                this.alertsService.createAlert({
                    alertType: AlertType.Error,
                    alertMessage: 'An error occurred while retrieving the pending event. Please try again.'
                });

                return observableOf(undefined);
            })
        );
    }

    public createEvent(
        request: CreateEventRequest,
        thumbnailType?: string | null
    ): Observable<CreateEventResponse> {
        const postEvent: () => Observable<any> = () => {
            let endpoint = `${this.apiUrl}/events`;

            if (thumbnailType) {
                endpoint += `?imagetype=${thumbnailType}`;
            }

            return this.http.post<any>(endpoint, request).pipe(
                catchError(() => {
                    this.alertsService.createAlert({
                        alertType: AlertType.Error,
                        alertMessage:
                            'An error occurred while saving your event. Please try again. If the problem persists, please contact an administrator.',
                    });

                    const response: CreateEventResponse = {
                        successful: false,
                    };

                    return observableOf(response);
                })
            );
        };

        return postEvent().pipe(
            map<any, CreateEventResponse>((apiResponse) => {
                return {
                    successful: apiResponse.eventId !== undefined,
                    thumbnailUploadUrl: apiResponse.s3SignedUrl,
                };
            }),
            tap((response) => {
                if (response.successful) {
                    this.alertsService.createAlert({
                        alertType: AlertType.Success,
                        alertMessage: 'Your event has been created!',
                    });

                    this.analyticsService.logEvent({
                        eventName: AnalyticsEvents.NEW_PENDING_EVENT
                    });
                }
            })
        );
    }
}
