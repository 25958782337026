<div class="d-flex flex-column align-items-center w-100 mx-auto text-center" id="container">
    <div class="header">Login</div>
    <div *ngIf="confirmed" class="confirmed">Your account has been confirmed! Please sign in below.</div>

    <form [formGroup]="form" class="w-100 p-2" (ngSubmit)="login()">
        <div class="d-flex flex-column">
            <app-text-field class="my-2" type="email" [control]="form.controls['email']" label="Email Address">
            </app-text-field>
            <app-text-field class="my-2" type="password" [control]="form.controls['password']" label="Password">
            </app-text-field>
        </div>

        <div class="d-grid mt-4">
            <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Login</button>
        </div>
    </form>

    <div class="ms-2 signup-link" role="button" routerLink="/forgot">Forgot Password?</div>

    <div class="d-flex">
        <div>Need to register?</div>
        <div class="ms-2 signup-link" role="button" routerLink="/signup">Sign Up</div>
    </div>
</div>