import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';



@NgModule({
    declarations: [
        ImageCarouselComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ImageCarouselComponent
    ]
})
export class SharedImagesModule { }
