import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-date-time-field',
    templateUrl: './date-time-field.component.html',
    styleUrls: ['./date-time-field.component.scss']
})
export class DateTimeFieldComponent {
    // HTML helpers
    public readonly objectFn = Object;

    @Input() public control: FormControl | undefined;
    @Input() public label: string | undefined = '';
    @Input() public required: boolean = false;
}
