export enum AnalyticsEvents {
    EVENT_CANCELLED = 'Event_Cancelled',
    EVENT_COMMENTED = 'Event_Commented',
    EVENT_VIEWED = 'Event_Viewed',
    NEW_SIGN_UP = 'New_Sign_Up',
    NEW_PENDING_EVENT = 'New_Pending_Event',
    PAGE_VISIT = 'Page_Visit',
    PENDING_EVENT_UPDATED = 'Pending_Event_Updated',
    PENDING_EVENT_CANCELLED = 'Pending_Event_Cancelled',
    EVENT_UPDATE_REQUEST_CREATED = 'Event_Update_Request_Created'
}