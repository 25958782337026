export class PhoneFormatter {
    public static formatPhoneNumber(phoneNumber: string): string | undefined {
        if (!phoneNumber) return undefined;

        const phoneNumberSections = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (phoneNumberSections) {
            return '(' + phoneNumberSections[1] + ') ' + phoneNumberSections[2] + '-' + phoneNumberSections[3];
        }

        return phoneNumber;
    }
}