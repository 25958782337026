import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as bootstrap from 'bootstrap';
import { UserSignupRequest } from 'src/app/auth/requests/user-signup.request';
import { AuthService } from 'src/app/auth/services/auth.service';
import { FormControlGenerationService } from 'src/app/shared/forms/services/form-control-generation.service';
import { FormValidationService } from 'src/app/shared/forms/services/form-validation.service';

@Component({
    selector: 'app-individual-registration-form',
    templateUrl: './individual-registration-form.component.html',
    styleUrls: ['./individual-registration-form.component.scss']
})
export class IndividualRegistrationFormComponent implements AfterViewInit {
    @Output() public formSubmitted = new EventEmitter<string>();

    public form = new FormGroup({
        firstName: new FormControl('', [this.formValidationService.requiredField('First Name')]),
        lastName: new FormControl('', [this.formValidationService.requiredField('Last Name')]),
        email: new FormControl('', [this.formValidationService.requiredField('Email')]),
        password: this.formControlGenerationService.createPasswordForm(),
        confirmPassword: new FormControl(''),
    }, {
        validators: [
            this.formValidationService.fieldsMatch({
                sourceField: 'password',
                confirmationField: 'confirmPassword',
                errorName: 'passwordMismatch',
                errorMessage: 'Passwords must match'
            })
        ]
    });

    constructor(
        private authService: AuthService,
        private formValidationService: FormValidationService,
        private formControlGenerationService: FormControlGenerationService
    ) { }

    public ngAfterViewInit(): void {
        const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

        tooltipElements.forEach((element) => {
            new bootstrap.Tooltip(element);
        });
    }

    public register(): void {
        if (this.form.valid) {
            const user = this.form.value;

            const request: UserSignupRequest = {
                name: user.firstName!,
                given_name: user.firstName!,
                family_name: user.lastName!,
                email: user.email!,
            };

            this.authService.signup(request, user.password!).subscribe((success) => {
                if (success) {
                    this.formSubmitted.emit(user.email!);

                    this.form.reset();
                }
            });
        }
    }
}
