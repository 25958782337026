<div class="d-flex flex-column align-items-center w-100 mx-auto text-center">
    <div class="header">Forgot Password</div>
    <form [formGroup]="form" (ngSubmit)="resetPassword()">
        <div>Please enter your email:</div>
        <app-text-field class="mt-4 p-2" type="text" [control]="form.controls['email']" label="Enter email here">
        </app-text-field>
    
        <div class="mt-3 d-grid">
            <button class="btn btn-primary" type="submit" role="button" [disabled]="form.invalid">Submit</button>
        </div>
        <div class="mt-4 link" role="button" routerLink="/login">Go Back</div>
    </form>
</div>