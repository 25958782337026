import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ExistingLoginGuard  {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    public canActivate(): Observable<boolean> {
        return this.authService.getIdToken().pipe(
            tap((idToken) => {
                if (idToken !== undefined) {
                    this.router.navigate(['/home']);
                }
            }),
            map((idToken) => idToken === undefined)
        );
    }

    public canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
