<div class="w-100 h-100 position-relative">
  <ng-content></ng-content>
  <span
    *ngIf="showProgress"
    class="spinner-border position-absolute top-0 bottom-0 start-0 end-0 m-auto"
    [style.height]="size + 'px'"
    [style.width]="size + 'px'"
    role="status"
  >
  </span>
</div>
