<div class="d-flex justify-content-center mt-4">
    <div id="content-holder">
        <div class="d-flex justify-content-center">
            <img class="xpwow-logo" src="assets/images/xpwow-lemlime-clear1.png">
        </div>

        <div class="xpwow-text">Download XPWOW to see if other explorers have been here!</div>

        <div id="app-store-buttons" class="d-flex justify-content-center mt-4">
            <a href="https://apps.apple.com/us/app/xpwow/id1585865938">
                <img class="apple-btn" src="assets/images/apple-app-store-badge.svg" alt="Download on the App Store">
            </a>
            <a
                href='https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.acg.xpwow&ddl=1&pcampaignid=web_ddl_1'>
                <img alt='Get it on Google Play' class="google-button" src='assets/images/google-play-badge.png' />
            </a>
        </div>
    </div>
</div>