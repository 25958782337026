import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventSearchResultsPageComponent } from './components/event-search-results-page/event-search-results-page.component';
import { RouterModule } from '@angular/router';
import { EventFiltersComponent } from './components/event-filters/event-filters.component';
import { EventSearchComponent } from './components/event-search/event-search.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EventsSharedModule } from '../shared/events-shared.module';

@NgModule({
    declarations: [
        EventFiltersComponent,
        EventSearchComponent,
        EventSearchResultsPageComponent
    ],
    imports: [
        CommonModule,
        EventsSharedModule,
        RouterModule,
        SharedModule,
    ],
    exports: [
        EventFiltersComponent,
        EventSearchComponent,
        EventSearchResultsPageComponent
    ]
})
export class EventsSearchModule { }
