import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XPWOWAdComponent } from './components/xpwow-ad/xpwow-ad.component';



@NgModule({
    declarations: [
        XPWOWAdComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [XPWOWAdComponent]
})
export class SharedAdsModule { }
