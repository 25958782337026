import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class PasswordValidationService {
    public requireUppercaseCharacter(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            const uppercaseError = { [`upperCase`]: 'Password must have at least one uppercase letter' };

            const value = control.value;
            const hasUppercase = new RegExp(/[A-Z]/g).test(value);

            if (!hasUppercase) {
                const errors = { ...uppercaseError, ...control.errors };
                control.setErrors(errors);
            }

            return !hasUppercase ? uppercaseError : null;
        }
    }

    public requireLowercaseCharacter(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            const lowercaseError = { [`lowerCase`]: 'Password must have at least one lowercase letter' };

            const value = control.value;
            const hasLowercase = new RegExp(/[a-z]/g).test(value);

            if (!hasLowercase) {
                const errors = { ...lowercaseError, ...control.errors };
                control.setErrors(errors);
            }

            return !hasLowercase ? lowercaseError : null;
        }
    }

    public requireSpecialCharacter(allowedSpecialCharacters: RegExp): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            const specialCharacterError = { [`specialCharacter`]: 'Password must have at least one special character' };

            const value = control.value;
            const hasSpecialCharacter = allowedSpecialCharacters.test(value);

            if (!hasSpecialCharacter) {
                const errors = { ...specialCharacterError, ...control.errors };
                control.setErrors(errors);
            }

            return !hasSpecialCharacter ? specialCharacterError : null;
        }
    }

    public requireNumber(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            const numberError = { [`missingNumber`]: 'Password must have at least one number' };

            const value = control.value;
            const hasNumber = new RegExp(/[0-9]/g).test(value);

            if (!hasNumber) {
                const errors = { ...numberError, ...control.errors };
                control.setErrors(errors);
            }

            return !hasNumber ? numberError : null;
        }
    }
}