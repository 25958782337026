import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedIconsModule } from './icons/shared-icons.module';
import { SharedProgressModule } from './progress/progress.module';
import { SharedTimeModule } from './time/time.module';
import { SharedFormsModule } from './forms/forms.module';
import { SharedImagesModule } from './images/images.module';
import { SharedModalModule } from './modal/modal.module';
import { SharedAdsModule } from './ads/ads.module';
import { SharedAssetsModule } from './assets/shared-assets.module';
import { SharedDropdownModule } from './dropdown/shared-dropdown.module';


@NgModule({
    imports: [
        CommonModule,
        SharedAdsModule,
        SharedAssetsModule,
        SharedDropdownModule,
        SharedFormsModule,
        SharedIconsModule,
        SharedImagesModule,
        SharedModalModule,
        SharedProgressModule,
        SharedTimeModule
    ],
    exports: [
        SharedAdsModule,
        SharedAssetsModule,
        SharedDropdownModule,
        SharedFormsModule,
        SharedIconsModule,
        SharedImagesModule,
        SharedModalModule,
        SharedProgressModule,
        SharedTimeModule
    ]
})
export class SharedModule { }
