import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Timeframes } from "../enums/timeframes.enum";
import { DateRange } from "../models/date-range";

@Injectable()
export class TimeService {
    public convertTimeframeToTimestamps(timeframe: Timeframes): DateRange | undefined {
        const date = new Date();
        const ONE_DAY_IN_MS = 86400000;

        const today = new Date().setHours(0, 0, 0, 0);
        switch (timeframe) {
            case Timeframes.TODAY:
                return {
                    startTime: date.valueOf(),
                    endTime: new Date(today + ONE_DAY_IN_MS - 1).valueOf()
                };
            case Timeframes.TOMORROW:
                return {
                    startTime: today + ONE_DAY_IN_MS,
                    endTime: today + ONE_DAY_IN_MS + ONE_DAY_IN_MS - 1
                }
            case Timeframes.THIS_MONTH:
                return {
                    startTime: today + (ONE_DAY_IN_MS * 2),
                    endTime: today + (ONE_DAY_IN_MS * 29) + ONE_DAY_IN_MS - 1
                }
            default:
                console.warn(`No conversion function available for timeframe ${timeframe}`);
                return undefined;
        }
    }

    public convertDateStringToEpochMilliseconds(date: string): number {
        const dateVal = moment(date);

        return dateVal.valueOf();
    }

    public convertEpochMillisecondsToDateString(epoch: number): string {
        const date = moment(epoch);
        const dateString = date.format("YYYY-MM-DDTHH:mm:ss");

        return dateString;
    }
}