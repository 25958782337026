import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signup-page',
    templateUrl: './signup-page.component.html',
    styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent {
    public individual: boolean = true;
    public organization: boolean = false;

    constructor(
        private router: Router,
    ) { }

    public setIndividual(): void {
        this.individual = true;
        this.organization = false;
    }

    public setOrganization(): void {
        this.organization = true;
        this.individual = false;
    }

    public navigateToConfirmationScreen(username?: string | undefined): void {
        this.router.navigate(['/confirm'], { state: { username: username } });
    }
}
