<div *ngIf="control">
  <input
    class="w-100"
    [type]="type"
    [formControl]="control"
    [placeholder]="label"
    (ngModelChange)="modelChange.emit($event)"
    autocomplete="off"
  />
  <ng-container *ngIf="control!.errors && control!.touched">
    <div
      class="error ps-2 text-start"
      *ngFor="let key of objectFn.keys(control!.errors!)"
    >
      {{ control!.errors![key] }}
    </div>
  </ng-container>
</div>
