<div class="h-100">
    <div id="carouselControls" class="carousel slide pb-4 h-100">
        <div *ngIf="showCarouselControls" class="carousel-indicators mb-0">
            <button *ngFor="let image of imageUrls; let i = index;" type="button" data-bs-target="#carouselControls"
                [attr.data-bs-slide-to]="i" [ngClass]="{'active': i === selectedIndex }"></button>
        </div>
        <div class="carousel-inner h-100" [ngClass]="{'w-75 mx-auto': showCarouselControls }">
            <div class="carousel-item d-flex justify-content-center h-100" [ngClass]="{'active': i === selectedIndex }"
                *ngFor="let image of imageUrls; let i = index">
                <div class="m-auto h-100">
                    <img class="h-100" [src]="image" />
                </div>
            </div>
        </div>
        <a *ngIf="showCarouselControls" class="carousel-control-prev" (click)="selectPrevious()">
            <span class="carousel-control-prev-icon"></span>
        </a>
        <a *ngIf="showCarouselControls" class="carousel-control-next" (click)="selectNext()">
            <span class="carousel-control-next-icon"></span>
        </a>
    </div>
</div>