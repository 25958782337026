import { environment as sharedEnvironment } from './environment.shared';

export const environment = {
    production: false,
    authConfig: {
        userPoolId: 'us-east-1_UdZB7pxPs',
        clientId: 's4v64jst99drpi5giplt5o3df',
        region: 'us-east-1',
    },
    google: {
        mapsAPIUrl: 'https://maps.googleapis.com/maps/api/js',
        apiKey: 'AIzaSyA-GnoFZT4E6W23I3zLKooXAzdQhAwfN1E',
    },
    apiUrl: 'https://eventtube.api.dev.v1.oneaaa.com',
    baseUrl: 'https://eventtube.dev.oneaaa.com',

    ...sharedEnvironment
};
