import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-image-carousel',
    templateUrl: './image-carousel.component.html',
    styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent {
    @Input() public imageUrls: string[] = [];
    public selectedIndex: number = 0;

    public get showCarouselControls(): boolean {
        return this.imageUrls?.length > 1;
    }

    public selectPrevious(): void {
        if (this.selectedIndex !== 0) {
            this.selectedIndex--;
        }
    }

    public selectNext(): void {
        if (this.selectedIndex !== this.imageUrls.length - 1) {
            this.selectedIndex++;
        }
    }
}
