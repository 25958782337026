<table class="table table-responsive">
  <thead>
    <tr>
      <th scope="col">Event</th>
      <th scope="col">Location</th>
      <th scope="col">Date</th>
      <th scope="col">Category</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="events?.length === 0">
      <div class="mt-2">
        <strong>No events found.</strong>
      </div>
    </tr>
    <ng-container *ngIf="events?.length && events?.length! > 0">
      <tr
        *ngFor="let event of events"
        role="button"
        (click)="eventSelected.emit(event.id)"
      >
        <td>
          <div class="d-flex">
            <img
              [src]="
                event.thumbnailUrls && event.thumbnailUrls.length > 0
                  ? event.thumbnailUrls[0]
                  : 'assets/images/concert-default.jpg'
              "
              onerror="this.src='assets/images/concert-default.jpg'; this.onerror=''"
            />
            <div class="ms-2 my-auto">{{ event.title }}</div>
          </div>
        </td>
        <td class="align-middle">
          <div>{{ event.address.addressOne }},</div>
          <div>{{ event.address.city }}, {{ event.address.state }}</div>
        </td>
        <td class="align-middle">
          <div class="text-center">
            <div>{{ event.startDateTime | date : EVENT_TIME_FORMAT }}</div>
            <div>to</div>
            <div>{{ event.endDateTime | date : EVENT_TIME_FORMAT }}</div>
          </div>
        </td>
        <td class="align-middle">
          <span class="badge rounded-pill bg-secondary">{{
            event.category
          }}</span>
        </td>
        <td class="align-middle" (click)="$event.stopPropagation()">
          <div class="d-flex btn-group justify-content-center">
            <button
              *ngIf="allowApprove"
              class="btn btn-success"
              (click)="approve.emit(event)"
              [disabled]="approvingEvent || denyingEvent"
            >
              <app-spinner-overlay
                *ngIf="approvingEvent && event.id === selectedEventId"
                [showProgress]="approvingEvent && event.id === selectedEventId"
              >
              </app-spinner-overlay>
              <div
                class="d-flex justify-content-center"
                [ngClass]="{
                  invisible: approvingEvent && event.id === selectedEventId
                }"
              >
                <app-icon icon="check"></app-icon>
                <div class="ms-1">Approve</div>
              </div>
            </button>
            <button
              class="btn btn-primary"
              (click)="edit.emit(event.id)"
              [disabled]="approvingEvent || denyingEvent"
            >
              <div class="d-flex justify-content-center">
                <app-icon icon="edit-2"></app-icon>
                <div class="ms-1">Edit</div>
              </div>
            </button>
            <button
              *ngIf="allowDeny"
              class="btn btn-danger"
              (click)="deny.emit(event)"
              [disabled]="approvingEvent || denyingEvent"
            >
              <app-spinner-overlay
                *ngIf="denyingEvent && event.id === selectedEventId"
                [showProgress]="denyingEvent && event.id === selectedEventId"
              ></app-spinner-overlay>
              <div
                class="d-flex justify-content-center"
                [ngClass]="{
                  invisible: denyingEvent && event.id === selectedEventId
                }"
              >
                <app-icon icon="trash-2"></app-icon>
                <div class="ms-1">Deny</div>
              </div>
            </button>
            <button
              *ngIf="allowDelete"
              class="btn btn-danger"
              (click)="delete.emit(event)"
              [disabled]="deletingEvent"
            >
              <app-spinner-overlay
                *ngIf="deletingEvent && event.id === selectedEventId"
                [showProgress]="deletingEvent && event.id === selectedEventId"
              ></app-spinner-overlay>
              <div
                class="d-flex justify-content-center"
                [ngClass]="{
                  invisible: deletingEvent && event.id === selectedEventId
                }"
              >
                <app-icon icon="trash-2"></app-icon>
                <div class="ms-1">Delete</div>
              </div>
            </button>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
