import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidationService } from 'src/app/shared/forms/services/form-validation.service';
import { UserLoginRequest } from '../../requests/user-login.request';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    public confirmed: boolean = false;

    public form = new FormGroup({
        email: new FormControl('', this.formValidationService.requiredField('Email address')),
        password: new FormControl('', this.formValidationService.requiredField('Password')),
    });

    constructor(
        @Inject('Window') private window: Window,
        private authService: AuthService,
        private router: Router,
        private formValidationService: FormValidationService
    ) { }

    public ngOnInit(): void {
        this.confirmed = this.window?.history?.state?.confirmed;
    }

    public login(): void {
        if (this.form.valid) {
            const form = this.form.value;

            const request: UserLoginRequest = {
                username: form.email!,
                password: form.password!,
            };

            this.authService.login(request).subscribe((response) => {
                if (response.successful) {
                    this.router.navigate(['/home']);
                }

                else {
                    if (response.error === 'User is not confirmed.') {
                        this.authService.resendConfirmationCode(request.username);

                        this.router.navigate(['/confirm'], { state: { username: request.username } });
                    }
                }
            });
        }
    }
}
