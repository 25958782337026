/*************************************************************************************
* Icon Component
*
* A shared component used to display an icon. Icons are generated programatically using
* Feather.
* 
* Date Created: 6/10/2021
* Author: Matthew Ainsworth
*
*************************************************************************************/

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent {
    @Input() public icon!: string;
}
