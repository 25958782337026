import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import { AlertBarComponent } from './components/alert-bar/alert-bar.component';
import { AlertsService } from './services/alerts.service';



@NgModule({
    declarations: [
        AlertComponent,
        AlertBarComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [
        AlertsService
    ],
    exports: [
        AlertComponent,
        AlertBarComponent
    ]
})
export class AlertsModule { }
