import { Injectable } from '@angular/core';

import { map, Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard  {
    constructor(private authService: AuthService) { }

    public canActivate(): Observable<boolean> {
        return this.authService.getIdToken().pipe(
            tap((idToken) => {
                if (!idToken) {
                    console.error('USER HAS INVALID TOKEN');
                }
            }),
            map((idToken) => idToken !== undefined)
        );
    }

    public canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
