import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, defer, filter, Observable, ReplaySubject, switchMap } from 'rxjs';
import { BaseModalComponent } from '../components/base-modal/base-modal.component';

export interface ModalMetadata {
    data?: any;
    onInit?: Function;
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private container: ViewContainerRef | undefined;

    private modalRef: ComponentRef<BaseModalComponent> | undefined;
    private modalId: string | undefined;

    private responseMap: Map<string, ReplaySubject<any | undefined>> = new Map();

    private initialized = new BehaviorSubject<boolean>(false);

    public registerModalContainer(container: ViewContainerRef): void {
        this.container = container;
        this.initialized.next(true);
    }

    public openModal<R>(component: Type<BaseModalComponent>, metadata?: ModalMetadata): Observable<R> {
        return this.initialized.pipe(
            filter((initialized) => initialized),
            switchMap(() => {
                this.closeModal();

                this.modalRef = this.container?.createComponent(component);
                const modal = this.modalRef?.instance!;
                const modalId = modal.modalId;
                this.modalId = modalId;

                const response = new ReplaySubject<any | undefined>(1, 5000);
                this.responseMap.set(modalId, response);

                if (metadata?.data) {
                    this.updateState(metadata.data);
                }

                return defer(() => {
                    if (metadata?.onInit) {
                        metadata?.onInit();
                    }

                    return response.asObservable();
                });
            })
        );
    }

    public updateState(metadata: any): void {
        const metadataKeys = Object.keys(metadata);

        metadataKeys.forEach((key) => {
            this.modalRef?.setInput(key, metadata[key]);
        });
    }

    public sendResponse(response: any, modalId: string | undefined = this.modalId): void {
        if (modalId) {
            const subject = this.responseMap.get(modalId);

            if (subject) {
                subject.next(response);
            }
        }
    }

    public closeModal(response?: any): void {
        const existingModalId = this.modalId;

        if (response && this.modalId) {
            this.sendResponse(response, this.modalId);
        }

        if (this.modalRef && this.modalId === existingModalId) {
            this.modalRef?.destroy();
            this.responseMap.delete(this.modalId!);
            this.modalRef = undefined;
            this.modalId = undefined;
        }
    }
}