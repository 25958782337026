import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThumbnailSelectorModalComponent } from './components/thumbnail-selector/thumbnail-selector-modal.component';
import { AssetsService } from './services/assets.service';
import { AssetsDataService } from './services/assets-data.service';
import { SharedProgressModule } from '../progress/progress.module';
import { SharedIconsModule } from '../icons/shared-icons.module';
import { SharedModalModule } from '../modal/modal.module';
import { SharedDropdownModule } from '../dropdown/shared-dropdown.module';

@NgModule({
    declarations: [
        ThumbnailSelectorModalComponent
    ],
    imports: [
        CommonModule,
        SharedDropdownModule,
        SharedIconsModule,
        SharedModalModule,
        SharedProgressModule
    ],
    providers: [
        AssetsService,
        AssetsDataService
    ],
    exports: [
        ThumbnailSelectorModalComponent
    ]
})
export class SharedAssetsModule { }
