import { Injectable } from '@angular/core';
import { FormValidationService } from './form-validation.service';
import { PasswordValidationService } from './password-validation.service';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormControlGenerationService {

  constructor(
    private formValidationService: FormValidationService,
    private passwordValidationService: PasswordValidationService
  ) { }

  public createPasswordForm(): FormControl {
    return new FormControl('', [
        this.formValidationService.requiredField('Password'),
        this.formValidationService.minimumLength(8),
        this.passwordValidationService.requireUppercaseCharacter(),
        this.passwordValidationService.requireLowercaseCharacter(),
        this.passwordValidationService.requireSpecialCharacter(/[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`\+\=]/),
        this.passwordValidationService.requireNumber(),
    ])
}
}
