import { Injectable } from '@angular/core';
import { CATEGORIES } from '../enums/categories';

@Injectable({
    providedIn: 'root'
})
export class TagsService {
    constructor() { }

    public getTags(): string[] {
        const tags = Object.entries(CATEGORIES).map(([, category]) => {
            return category;
        });

        return tags;
    }
}
