<form [formGroup]="form" class="d-lg-flex">
  <div class="btn-group d-none d-lg-block">
    <button
      type="button"
      class="btn btn-primary-alt-md dropdown-toggle me-1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-auto-close="outside"
    >
      Filter
    </button>
    <div class="dropdown-menu pt-3 px-2">
      <app-event-filters
        [form]="form.controls['filters']"
        [searchMethod]="searchEvents.bind(this)"
      ></app-event-filters>
    </div>
  </div>
  <input
    class="form-control me-2"
    type="search"
    placeholder="Search"
    aria-label="Search"
    formControlName="searchTerm"
  />
  <div class="d-lg-none my-4">
    <app-event-filters
      [form]="form.controls['filters']"
      [searchMethod]="searchEvents.bind(this)"
    ></app-event-filters>
  </div>
  <button
    class="btn btn-success d-grid d-md-block col-10 col-md-1 mx-auto search-button"
    type="submit"
    (click)="searchEvents()"
    [disabled]="form.invalid"
  >
    Search
  </button>
</form>
