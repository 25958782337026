<div class="d-flex flex-column align-items-center w-100 mx-auto text-center">
    <div id="header">Register</div>

    <div class="mt-2">
        <button class="tab" [ngClass]="{'selected' : individual}" (click)="setIndividual()">Individual</button>
        <button class="tab" [ngClass]="{'selected' : organization}" (click)="setOrganization()">Organization</button>
    </div>

    <app-individual-registration-form *ngIf="individual"
        (formSubmitted)="navigateToConfirmationScreen($event)"></app-individual-registration-form>

    <app-organization-registration-form *ngIf="organization"
        (formSubmitted)="navigateToConfirmationScreen($event)"></app-organization-registration-form>

    <div class="login-div d-flex">
        <div>Already have an account?</div>
        <div routerLink="/login" class="login-link ms-1" role="button">Login</div>
    </div>
</div>