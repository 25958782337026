import { Component, Input } from '@angular/core';
import { Event } from '../../../shared/models/event';
import { EVENT_TIME_FORMAT } from '../../config/events-shared-settings';

@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent {
    public readonly EVENT_TIME_FORMAT = EVENT_TIME_FORMAT;

    @Input() public event!: Event;
}
