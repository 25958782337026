import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { UserData } from '../models/user-data';
import { User } from "../models/user";


@Injectable()
export class UserService {
    private _currentUser = new BehaviorSubject<User | undefined>(undefined);

    public get currentUser(): Observable<User | undefined> {
        return this._currentUser.asObservable();
    }

    public setCurrentUser(userData?: UserData): void {
        if (!userData) {
            this._currentUser.next(undefined);

            return;
        }

        var user = userData as User;

        this._currentUser.next(user);
    }

}
