import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventSearchResultsPageComponent } from './events/search/components/event-search-results-page/event-search-results-page.component';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'events/search',
        component: EventSearchResultsPageComponent
    },
    {
        path: 'events/manage',
        loadChildren: () => import('./events/admin/events-admin.module').then(m => m.EventsAdminModule)
    },
    {
        path: 'events',
        loadChildren: () => import('./events/details/event-details.module').then(m => m.EventDetailsModule)
    },
    {
        path: '', pathMatch: 'full', redirectTo: 'home'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
