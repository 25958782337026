import { Inject, Injectable } from '@angular/core';
import { CognitoUserAttribute, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import { Observable, Subject, take, from as observableFrom, of as observableOf, tap, throwError, switchMap, ReplaySubject, map } from 'rxjs';
import { AuthConfig } from '../configs/auth.config';
import { AUTH_CONFIG_TOKEN } from '../tokens';
import {
    AuthenticationDetails,
    CognitoUser,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { UserLoginRequest } from '../requests/user-login.request';
import { UserService } from 'src/app/user/services/user.service';
import { UserData } from 'src/app/user/models/user-data';
import { User } from 'src/app/user/models/user';
import { AlertsService } from 'src/app/alerts/services/alerts.service';
import { AlertType } from 'src/app/alerts/types/alert.type';
import { UserLoginResponse } from '../responses/user-login.response';
import { UserSignupRequest } from '../requests/user-signup.request';


@Injectable({
    providedIn: 'root'
})
export class CognitoService {
    private cognitoUserPool: CognitoUserPool | undefined;

    constructor(
        @Inject(AUTH_CONFIG_TOKEN) private authConfig: AuthConfig,
        private userService: UserService,
        private alertsService: AlertsService
    ) {
        this.startupService();
    }

    public startupService(): void {
        var poolData: ICognitoUserPoolData = {
            UserPoolId: this.authConfig.userPoolId,
            ClientId: this.authConfig.clientId,
        };

        this.cognitoUserPool = new CognitoUserPool(poolData);
    }

    public getCurrentUser(): CognitoUser | undefined {
        var user = this.cognitoUserPool?.getCurrentUser() ?? undefined

        return user;
    }

    public isUserLoginValid(): Observable<boolean> {
        var user = this.getCurrentUser();

        if (user) {
            return this.getUserSession().pipe(
                switchMap((session) => {
                    if (session) {
                        return observableOf(session.isValid());
                    }
                    else {
                        return this.refreshSession();
                    }
                })
            );

        }

        return observableOf(false);
    }

    public getUserSession(): Observable<CognitoUserSession | undefined> {
        const subject = new ReplaySubject<CognitoUserSession | undefined>(1);

        var user = this.getCurrentUser();

        if (user) {
            user.getSession((err: Error | null, session: CognitoUserSession | undefined) => {
                if (err) {
                    subject.error(err);
                }

                subject.next(session);
            });
        } else {
            subject.next(undefined);
        }

        return subject.asObservable().pipe(take(1));
    }

    public getIdToken(): Observable<string | undefined> {
        return this.getUserSession().pipe(
            map((session) => {
                if (session) {
                    return session.getIdToken().getJwtToken();
                }

                return undefined;
            })
        );
    }

    public getUserAttributes(): Observable<UserData | undefined> {
        return this.getUserSession().pipe(
            map((session) => {
                if (session) {
                    const token = session.getIdToken();

                    return new UserData({
                        username: token.payload['email'],
                        given: token.payload['given_name'],
                        family: token.payload['family_name'],
                        email: token.payload['email'],
                        userId: token.payload['sub'],
                    });
                }

                return undefined;
            })
        );
    }

    public getSession(): Observable<CognitoUserSession | null> {
        return observableFrom(new Promise<CognitoUserSession | null>((resolve, reject) => {
            const user = this.getCurrentUser();

            if (user) {
                user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err) {
                        console.error(err);
                        reject(err);
                    }

                    else {
                        resolve(session);
                    }
                });
            }
        }));
    }

    public refreshSession(): Observable<boolean> {

        const user = this.getCurrentUser();

        return observableOf(user).pipe(
            switchMap((user) => {
                if (!user) {
                    return observableOf(null);
                }

                return this.getSession();
            }),
            switchMap((session) => {
                if (!session || !user) {
                    return observableOf(false);
                }

                const subject = new ReplaySubject<boolean>(1);

                const refreshToken = session.getRefreshToken();

                user.refreshSession(refreshToken, (err: any) => {
                    if (err) {
                        console.error(err);
                        subject.error(err);
                    }

                    else {
                        subject.next(true);
                    }
                });

                return subject.asObservable().pipe(take(1));
            })
        );
    }

    public signup(request: UserSignupRequest, password: string): Observable<boolean> {
        const subject = new Subject<boolean>();
        let attributes: CognitoUserAttribute[] = [];

        if (request.given_name && request.family_name)
            attributes = [
                new CognitoUserAttribute({ Name: 'name', Value: request.given_name + ' ' + request.family_name }),

                new CognitoUserAttribute({ Name: 'given_name', Value: request.given_name }),

                new CognitoUserAttribute({ Name: 'family_name', Value: request.family_name })
            ]

        else attributes = [new CognitoUserAttribute({ Name: 'name', Value: request.name })]

        this.cognitoUserPool?.signUp(request.email, password, attributes,
            [],
            (error: any) => {
                if (error?.message) {
                    this.alertsService.createAlert({
                        alertType: AlertType.Error,
                        alertMessage: error?.message
                    });

                    throwError(() => {
                        throw new Error(error.message);
                    });
                }
                if (!error) {
                    subject.next(true);
                }
            }
        );

        return subject.asObservable();
    }

    public login(request: UserLoginRequest): Observable<UserLoginResponse> {
        const loginResult = new Subject<UserLoginResponse>();

        if (!this.cognitoUserPool) loginResult.next({
            successful: false,
            error: 'Authorization pool has not been set up'
        });

        const userPool = this.cognitoUserPool;
        const user = new CognitoUser({ Username: request.username, Pool: userPool! });

        const authenticationData = { Username: request.username, Password: request.password };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        user.authenticateUser(authenticationDetails, {
            onSuccess: () => {
                const user = this.getCurrentUser();

                if (user) {
                    this.getUserAttributes().pipe(
                        take(1),
                        tap((userData) => {
                            if (userData) {
                                var newUser: User = {
                                    ...userData
                                };
                                this.userService.setCurrentUser(newUser);
                            }

                            loginResult.next({
                                successful: true
                            });
                        })
                    ).subscribe();
                };

            },
            onFailure: (err) => {
                this.alertsService.createAlert({
                    alertType: AlertType.Error,
                    alertMessage: err?.message ? err?.message : 'An error occurred while trying to log in. Please try again. If the problem persists, please contact a system administrator'
                });

                loginResult.next({
                    successful: false,
                    error: err?.message
                });
            }
        });

        return loginResult.asObservable().pipe(take(1));
    }

    public confirmUser(code: string, username: string): Observable<any> {
        const user = new CognitoUser({ Username: username, Pool: this.cognitoUserPool! });
        const result = new Subject<any>();

        user.confirmRegistration(code, false, (err, confirmUserResult) => {
            if (err) {
                console.error(err);

                result.error(err);
            } else {
                result.next(confirmUserResult);
            }
        });

        return result.asObservable().pipe(take(1));
    }

    public resendConfirmationCode(username: string): Observable<AWS.CognitoIdentityServiceProvider.ResendConfirmationCodeResponse> {
        const cognito = new AWS.CognitoIdentityServiceProvider({ region: this.authConfig['region'] });

        const params = {
            ClientId: this.authConfig['clientId'],
            Username: username,
        };

        return observableFrom(cognito.resendConfirmationCode(params).promise()).pipe(
            tap((response) => {
                if (response.$response.error) {
                    throwError(() => {
                        const error = response.$response.error as AWS.AWSError;
                        return new Error(error.message);
                    });
                }
            }),
            take(1)
        );
    }

    public resetPassword(username: string): Observable<boolean> {
        const subject = new Subject<boolean>();
        const user = new CognitoUser({ Username: username, Pool: this.cognitoUserPool! });

        user?.forgotPassword({
            onSuccess() {
                subject.next(true);
            },
            onFailure(err) {
                subject.error(err);
            }
        });

        return subject.asObservable();
    }

    public confirmResetPassword(username: string, newPassword: string, verificationCode: string): Observable<boolean> {
        const subject = new Subject<boolean>();
        const user = new CognitoUser({ Username: username, Pool: this.cognitoUserPool! });

        user?.confirmPassword(verificationCode, newPassword, {
            onSuccess() {
                subject.next(true);
            },
            onFailure(err: Error) {
                console.error(err);
                subject.error(err);
            }
        });

        return subject.asObservable();
    }

    public logout(): void {
        const user = this.getCurrentUser();

        if (user) user.signOut();
    }

}
