import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './components/icon/icon.component';
import { IconsService } from './services/icons.service';


@NgModule({
    declarations: [
        IconComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [
        IconsService
    ],
    exports: [
        IconComponent
    ]
})
export class SharedIconsModule { }
