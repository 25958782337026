import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthConfig } from './configs/auth.config';
import { CommonModule } from '@angular/common';

import { CognitoService } from './services/cognito.service';
import { AUTH_CONFIG_TOKEN } from './tokens';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { ConfirmationPageComponent } from './components/confirmation-page/confirmation-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SignupPageComponent } from './components/signup-page/signup-page.component';
import { RouterModule } from '@angular/router';
import { IndividualRegistrationFormComponent } from './components/signup-page/individual-registration-form/individual-registration-form.component';
import { OrganizationRegistrationFormComponent } from './components/signup-page/organization-registration-form/organization-registration-form.component';
import { SharedFormsModule } from '../shared/forms/forms.module';
import { ResetPasswordPageComponent } from './components/reset-password-page/reset-password-page.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { ExistingLoginGuard } from './guards/existing-login.guard';

const routes = [
    { path: 'login', canActivate: [ExistingLoginGuard], component: LoginPageComponent },
    { path: 'forgot', canActivate: [ExistingLoginGuard], component: ForgotPasswordPageComponent },
    { path: 'reset-password', canActivate: [ExistingLoginGuard], component: ResetPasswordPageComponent },
    { path: 'signup', canActivate: [ExistingLoginGuard], component: SignupPageComponent },
    { path: 'confirm', canActivate: [ExistingLoginGuard], component: ConfirmationPageComponent }
];

export function createAuthConfig(): AuthConfig {
    return {
        userPoolId: environment.authConfig.userPoolId,
        clientId: environment.authConfig.clientId,
        region: environment.authConfig.region,
    };
}

@NgModule({
    declarations: [
        ConfirmationPageComponent,
        ForgotPasswordPageComponent,
        IndividualRegistrationFormComponent,
        LoginPageComponent,
        OrganizationRegistrationFormComponent,
        ResetPasswordPageComponent,
        SignupPageComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedFormsModule
    ],
    providers: [
        AuthGuard,
        AuthService,
        CognitoService,
        ExistingLoginGuard,
        RouterModule,
        {
            provide: AUTH_CONFIG_TOKEN,
            useFactory: createAuthConfig,
        }
    ],
})
export class AuthModule { }
