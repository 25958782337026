import { Inject, Injectable } from "@angular/core";
import { catchError, map, Observable, of as observableOf, switchMap, tap } from "rxjs";
import { UserService } from "src/app/user/services/user.service";
import { CognitoService } from "./cognito.service";
import { Router } from "@angular/router";
import { APP_BASE_URL } from "src/app/shared/tokens";
import { AlertsService } from "src/app/alerts/services/alerts.service";
import { AlertType } from "src/app/alerts/types/alert.type";
import { UserLoginRequest } from "../requests/user-login.request";
import { UserLoginResponse } from "../responses/user-login.response";
import { UserSignupRequest } from "../requests/user-signup.request";
import { AnalyticsService } from "src/app/analytics/services/analytics.service";
import { AnalyticsEvents } from "src/app/analytics/enums/analytics-events";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _redirectUri?: string;

    constructor(
        private router: Router,
        private userService: UserService,
        private cognitoService: CognitoService,
        @Inject(APP_BASE_URL) private baseUrl: string,
        @Inject('Window') private window: Window,
        private alertsService: AlertsService,
        private analyticsService: AnalyticsService
    ) { }

    public startAuth(): Observable<boolean> {
        this._redirectUri = !this.isRedirectURIHome(this.window.location.href) ? decodeURIComponent(this.window.location.href) : this.window.location.href;

        return this.validateUserLogin();
    }

    public login(request: UserLoginRequest): Observable<UserLoginResponse> {
        return this.cognitoService.login(request);
    }

    public getIdToken(): Observable<string | undefined> {
        return this.cognitoService.getIdToken();
    }

    public redirectUser(): void {
        if (!this._redirectUri) return;

        const route = this.getRouteFromURI(this._redirectUri);

        this.router.navigateByUrl(route);
    }

    public signup(request: UserSignupRequest, password: string): Observable<boolean> {
        return this.cognitoService.signup(request, password).pipe(
            tap((signedUp) => {
                if (signedUp) {
                    this.analyticsService.logEvent({
                        eventName: AnalyticsEvents.NEW_SIGN_UP
                    });
                }
            }),
            catchError(() => {
                return observableOf(false);
            })
        );
    }

    public resendConfirmationCode(username: string): void {
        this.cognitoService.resendConfirmationCode(username).subscribe();
    }

    public confirmUser(code: string, username: string): Observable<boolean> {
        return this.cognitoService.confirmUser(code, username).pipe(
            map(() => true),
            catchError(() => observableOf(false))
        );
    }

    public resetPassword(username: string): Observable<boolean> {
        return this.cognitoService.resetPassword(username);
    }

    public confirmResetPassword(username: string, newPassword: string, verificationCode: string): Observable<boolean> {
        return this.cognitoService.confirmResetPassword(username, newPassword, verificationCode);
    }

    public isSessionValid(): Observable<boolean> {
        return this.cognitoService.getUserSession().pipe(
            map((session) => {
                return session?.isValid() ?? false;
            })
        );
    }

    public refreshSession(): Observable<boolean> {
        return this.cognitoService.refreshSession();
    }

    public logout(): void {
        this.cognitoService.logout();

        this.userService.setCurrentUser(undefined);

        this.alertsService.createAlert({
            alertType: AlertType.Success,
            alertMessage: 'You have successfully been logged out.'
        });

        this.router.navigateByUrl(`/login`);
    }

    private validateUserLogin(): Observable<boolean> {
        return this.cognitoService.isUserLoginValid().pipe(
            switchMap((isValid) => {
                if (isValid) {
                    return this.cognitoService.refreshSession().pipe(
                        switchMap(() => {
                            return this.cognitoService.getUserAttributes();
                        }),
                        tap((userData) => {
                            if (userData) {
                                this.userService.setCurrentUser(userData);
                            }
                        }),
                        map((userData) => {
                            return userData !== undefined;
                        })
                    );
                }

                return observableOf(false);
            })
        );
    }

    private isRedirectURIHome(redirectURI: string): boolean {
        const route = this.getRouteFromURI(redirectURI);

        return route === '' || route === `/home` || route === `/login`;
    }

    private getRouteFromURI(redirectURI: string) {
        const substringURI = redirectURI.substring(this.baseUrl.length);
        return substringURI.replace(/[/]$/, '');
    }
}
