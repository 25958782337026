import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsService } from './services/events.service';
import { EventCardComponent } from './components/event-card/event-card.component';
import { EventsRowComponent } from './components/events-row/events-row.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EventsTableComponent } from './components/events-table/events-table.component';

@NgModule({
    declarations: [
        EventCardComponent,
        EventsRowComponent,
        EventsTableComponent,
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    providers: [
        EventsService
    ],
    exports: [
        EventCardComponent,
        EventsRowComponent,
        EventsTableComponent,
    ]
})
export class EventsSharedModule { }
