<form [formGroup]="form" (ngSubmit)="register()">
    <div class="d-flex flex-column">
        <app-text-field class="my-2" [control]="form.controls['name']" label="Organization Name">
        </app-text-field>

        <app-text-field class="my-2" type="email" [control]="form.controls['email']" label="Email Address">
        </app-text-field>

        <app-password-field [control]="form.controls['password']"></app-password-field>

        <app-text-field class="my-2" type="password" [control]="form.controls['confirmPassword']"
            label="Confirm Password">
        </app-text-field>
    </div>

    <div class="d-grid">
        <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Register</button>
    </div>
</form>