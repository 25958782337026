import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AlertComponent } from '../components/alert/alert.component';
import { AlertMessage } from '../models/alert-message';
import { AlertType } from '../types/alert.type';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {
    private readonly DEFAULT_ALERT_MESSAGE_EXPIRATION_IN_MS = 5000;

    private _alertBar = new BehaviorSubject<ViewContainerRef | undefined>(undefined);

    private alertBar: Observable<ViewContainerRef | undefined> = this._alertBar.asObservable().pipe(
        filter((alertBar) => {
            return alertBar !== undefined && alertBar !== null;
        })
    );

    private _existingAlerts = new BehaviorSubject<ComponentRef<AlertComponent>[]>([]);

    public get existingAlerts(): Observable<ComponentRef<AlertComponent>[]> {
        return this._existingAlerts.asObservable();
    }

    constructor(
        private resolver: ComponentFactoryResolver
    ) { }

    public registerAlertBar(alertBarRef: ViewContainerRef): void {
        this._alertBar.next(alertBarRef);
    }

    public createAlert(message: AlertMessage, timeToExpireInMS?: number): void {
        this.alertBar.pipe(
            filter((alertBar) => alertBar !== undefined),
            take(1),
            switchMap((alertBar) => {
                const factory = this.resolver.resolveComponentFactory<AlertComponent>(AlertComponent);
                const alert = alertBar!.createComponent(factory);

                this.buildAlertMessage(alert, message);

                this.addAlertToExistingAlerts(alert);

                this.logMessage(message);

                return timer(timeToExpireInMS ? timeToExpireInMS : this.DEFAULT_ALERT_MESSAGE_EXPIRATION_IN_MS).pipe(
                    tap(() => {
                        this.removeAlertFromExistingAlerts(alert);
                    })
                );
            })
        ).subscribe();
    }

    private buildAlertMessage(alert: ComponentRef<AlertComponent>, message: AlertMessage): void {
        alert.instance.alertType = message.alertType;
        alert.instance.alertMessage = message.alertMessage;
    }

    private addAlertToExistingAlerts(alert: ComponentRef<AlertComponent>): void {
        this.existingAlerts.pipe(take(1)).subscribe((existingAlerts) => {
            this._existingAlerts.next([...existingAlerts, alert]);
        });
    }

    private removeAlertFromExistingAlerts(alert: ComponentRef<AlertComponent>): void {
        this.existingAlerts.pipe(
            take(1),
            tap((existingAlerts) => {
                existingAlerts.shift();
                this._existingAlerts.next([...existingAlerts]);
            })
        ).subscribe(() => {
            alert.destroy();
        });
    }

    private logMessage(message: AlertMessage): void {
        if (message.alertType === AlertType.Success) {
            console.info(message.alertMessage);
        }

        else if (message.alertType === AlertType.Warning) {
            console.warn(message.alertMessage);
        }

        else if (message.alertType === AlertType.Error) {
            console.error(message.alertMessage);
        }
    }
}
