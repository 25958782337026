import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL_TOKEN, APP_VERSION } from 'src/app/shared/tokens';
import * as Bowser from 'bowser';
import { UserContext } from '../models/user-context';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
    constructor(
        @Inject(API_URL_TOKEN) private apiUrl: string,
        @Inject(APP_VERSION) private appVersion: string,
        @Inject('Window') private window: Window
    ) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!req.url.startsWith(`${this.apiUrl}/analytics`)) return next.handle(req);

        const browser = Bowser.getParser(window.navigator.userAgent);
        const userContext: UserContext = {
            locale: navigator.language,
            platform: browser.getBrowserName(),
            platformVersion: browser.getBrowserVersion(),
            make: browser.getOSName(),
            model: browser.getPlatformType(),
            appVersion: this.appVersion,
            timezone: Intl.DateTimeFormat().resolvedOptions()
                .timeZone
        };

        const contextStringified = JSON.stringify(userContext);
        const contextEncoded = this.window.btoa(contextStringified)

        const newReq = req.clone({
            headers: req.headers.set('x-user-context', contextEncoded),
        });

        return next.handle(newReq);
    }
}
