import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event } from '../../../shared/models/event';

@Component({
    selector: 'app-events-row',
    templateUrl: './events-row.component.html',
    styleUrls: ['./events-row.component.scss']
})
export class EventsRowComponent {
    @Output() public eventSelected = new EventEmitter<string>();

    @Input() public events: Event[] = [];
}
