import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, of as observableOf } from 'rxjs';
import { API_URL_TOKEN } from '../../tokens';
import { GetThumbnailsDataResponse } from '../models/responses/get-thumbnails-data-response';

@Injectable()
export class AssetsDataService {

    constructor(private httpClient: HttpClient, @Inject(API_URL_TOKEN) private apiUrl: string) { }

    public getThumbnails(): Observable<GetThumbnailsDataResponse> {
        return this.httpClient.get<GetThumbnailsDataResponse>(`${this.apiUrl}/thumbnails`).pipe(
            catchError(() => {
                return observableOf({})
            })
        );
    }
}
