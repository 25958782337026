import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent {
  @Input() public label!: string;
  @Input() public selectedOption: string | undefined;
  @Input() public options: string[] = [];
  @Output() public optionSelected = new EventEmitter<string>();
}

