<form [formGroup]="form" (ngSubmit)="register()">
    <div class="d-flex flex-column">
        <app-text-field class="my-2" [control]="form.controls['name']" label="Organization Name">
        </app-text-field>

        <app-text-field class="my-2" type="email" [control]="form.controls['email']" label="Email Address">
        </app-text-field>

        <app-text-field class="my-2" type="password" [control]="form.controls['password']" label="Password">
        </app-text-field>
        <div class="text-muted text-start fst-italic">
            <div>Password requirements:</div>
            <ul>
                <li>At least 8 characters long</li>
                <li>At least one uppercase letter</li>
                <li>At least one lowercase letter</li>
                <li>
                    <div class="d-flex">
                        <div>At least one</div>
                        <a class="ms-1" data-bs-toggle="tooltip" data-bs-placement="top"
                            [attr.title]="allowedSpecialCharactersString">special character</a>
                    </div>
                </li>
                <li>At least one number</li>
            </ul>
        </div>

        <app-text-field class="my-2" type="password" [control]="form.controls['confirmPassword']"
            label="Confirm Password">
        </app-text-field>
    </div>

    <div class="d-grid">
        <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Register</button>
    </div>
</form>